import { template as template_148a64ae5baf40e0a0765f4e2cb18ebe } from "@ember/template-compiler";
const FKText = template_148a64ae5baf40e0a0765f4e2cb18ebe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_e8516fe584d345eeb31893064c260529 } from "@ember/template-compiler";
const FKLabel = template_e8516fe584d345eeb31893064c260529(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

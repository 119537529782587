import { template as template_3894b23693a84284b4cc3ec73efa09a6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3894b23693a84284b4cc3ec73efa09a6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
